import { MEDIA_QUERY_MEDIUM } from "../styles/variables";
import styled from "styled-components";

const ContentBlockStyles = styled.div`
  padding: 0 2rem 3rem;
  position: relative;
  min-height: 30vh;
  line-height: 1.5;

  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .h1 {
    background-color: var(--themeColour);
    display: inline-block;
    padding: 0.5rem 1.5rem 0.6rem;
    color: var(--white);
    font-weight: 500;
    transform: translateY(-50%);
    border-radius: var(--borderRadius);
    font-size: 2.5rem;
    @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
      font-size: 3rem;
      padding: 1rem 2rem;
    }
  }

  .cms-content {
    h2, h3 {
      color: var(--themeColour);
      font-weight: bold;

    }
  }


  .html {
    margin-top: -2rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ul {
    li {
      padding-bottom: 0.5rem;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
`;

export default ContentBlockStyles;
